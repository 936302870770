div.thehawkans-content{
  flex: 1;
  display: flex;
  align-items: center;
  padding: 60px 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#333333+0,050505+100 */
  background: rgb(51,51,51); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, rgba(51,51,51,1) 0%, rgba(5,5,5,1) 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(51,51,51,1) 0%,rgba(5,5,5,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(51,51,51,1) 0%,rgba(5,5,5,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#050505',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
div.thehawkans-content.front{
  background-image: url('../../images/meet-at-night-hawkansdotcom-cover28aug19.jpg');
  background-size: cover;
  background-position: center;
}

div.thehawkans-content > div.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
div.thehawkans-content.band > div.container{
  align-items: stretch;
}

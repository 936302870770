div.thehawkans-footer{
  background-color: rgba(0,0,0,1.0);
  text-align: center;
  padding: 10px 0;
  margin: 0;
  color: #ccc;
}
div.thehawkans-footer p{
  margin: 0;
  font: 14px "Chivo", sans-serif;
  text-transform: uppercase;
  color: #c13100;
  display: inline-block;
  white-space: nowrap;
}

html{
  height: 100%;
}
body{
  background-color: #000;
  height: 100%;
  min-width: 320px;
}

#root{
  height: 100%;
}

#root > div.thehawkans{
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
}

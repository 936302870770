html{
  height: 100%;
}
body{
  background-color: #000;
  height: 100%;
  min-width: 320px;
}

#root{
  height: 100%;
}

#root > div.thehawkans{
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
}

div.thehawkans-menu {
  background-color: rgba(0,0,0,1.0);
}
div.thehawkans-menu nav.navbar {
  padding-left: 0;
  padding-right: 0;
}
div.thehawkans-menu nav.navbar button.navbar-toggler:focus {
  outline: none
}
div.thehawkans-menu nav.navbar li a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px 0 2px 0;
  margin: auto 30px;
  border-bottom: solid 1px rgba(193, 49, 0, 0);
  -webkit-transition: .5s border-color;
  transition: .5s border-color;
}
@media (max-width: 768px){
  div.thehawkans-menu nav.navbar ul.navbar-nav {
    margin-top: 10px;
  }
  div.thehawkans-menu nav.navbar li {
    padding: 5px 0;
  }
}
div.thehawkans-menu nav.navbar li a:first-child {
  margin-left: 0;
}
div.thehawkans-menu nav.navbar li a {
  overflow: hidden;
  height: 30px;
  display: block;
}
div.thehawkans-menu nav.navbar li a div.navitem-wrapper {
  display: flex;
  flex-direction: column;
  -webkit-transition: .2s all;
  transition: .2s all;
}
div.thehawkans-menu nav.navbar li a span.on {
  color: #c13100;
}

div.thehawkans-menu nav.navbar li a:hover .navitem-wrapper {
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
}
div.thehawkans-menu nav.navbar li a.active .navitem-wrapper{
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
}

div.thehawkans-content{
  flex: 1 1;
  display: flex;
  align-items: center;
  padding: 60px 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#333333+0,050505+100 */
  background: rgb(51,51,51); /* Old browsers */ /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(51,51,51,1) 0%,rgba(5,5,5,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(51,51,51,1) 0%,rgba(5,5,5,1) 100%);
  background: radial-gradient(ellipse at center, rgba(51,51,51,1) 0%,rgba(5,5,5,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#050505',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
div.thehawkans-content.front{
  background-image: url(/static/media/meet-at-night-hawkansdotcom-cover28aug19.7c200d59.jpg);
  background-size: cover;
  background-position: center;
}

div.thehawkans-content > div.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
div.thehawkans-content.band > div.container{
  align-items: stretch;
}

img.logo{
  max-width: 430px;
  margin-bottom: 30px;
}
h1{
  font: 1.5rem "Chivo", sans-serif;
  text-transform: uppercase;
  color: #c13100;
  border-bottom: solid 1px #fff;
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 15px;
}
p{
  color: #d0d0d0;
  line-height: 1.5;
}
.front p{
  color: #fff;
  text-shadow: 1px 1px #000;
}
p a{
  color: #d0d0d0;
}
p a:hover{
  border-bottom: solid 1px #c13100;
  color: #fff;
  text-decoration: none;
}
.contact p{
  white-space: nowrap;
}
.emphasis{
  color: #c13100;
}
.slogan{
  font-size: 1rem;
  margin: 15px 0 45px 0;
  color: rgba(255,255,255,.6);
  border-bottom-color: #c13100;
}
.label{
  border-bottom: solid 1px #c13100;
}

@media (min-width: 768px){
  .slogan{
    font-size: 1.25rem;
  }
}
img{
  width: 100%;
  height: auto;
}
img.portrait{
  height: 100%;
  width: auto;
}
@media (min-width: 768px){
  .thehawkans-ultrawidetext{
    font-size: .875rem;
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    grid-column-gap: 18vw;
    -webkit-column-gap: 18vw;
       -moz-column-gap: 18vw;
            column-gap: 18vw;
  }
}
@media (min-width: 992px){
  .thehawkans-widetext{
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .thehawkans-extrawidetext{
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
       -moz-column-gap: 30px;
            column-gap: 30px;
    text-align: justify;
  }
  .thehawkans-ultrawidetext{
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 10vw;
    -webkit-column-gap: 10vw;
       -moz-column-gap: 10vw;
            column-gap: 10vw;
    text-align: justify;
  }
}
@media (min-width: 1200px){
  .thehawkans-extrawidetext{
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .thehawkans-ultrawidetext{
    /* column-count: 4; */
    grid-column-gap: 10vw;
    -webkit-column-gap: 10vw;
       -moz-column-gap: 10vw;
            column-gap: 10vw;
  }
}
hr{
  margin-top: 30px;
  margin-bottom: 60px;
  border: dashed 1px rgba(255,255,255,.1);
}
.embed-responsive iframe{
  background: #000;
}
.spreadspan{
  display: flex;
  justify-content: space-between;
}
div.thehawkans-footer{
  background-color: rgba(0,0,0,1.0);
  text-align: center;
  padding: 10px 0;
  margin: 0;
  color: #ccc;
}
div.thehawkans-footer p{
  margin: 0;
  font: 14px "Chivo", sans-serif;
  text-transform: uppercase;
  color: #c13100;
  display: inline-block;
  white-space: nowrap;
}



